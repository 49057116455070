.header-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 110;

  padding: 25px 45px;
  height: 95px;
  box-sizing: border-box;

  background-color: $ui-main-background;
  box-shadow: $top-bar-shadow;
  a {
    text-decoration: none;
  }
  .header-logo {
    display: flex;
    align-items: center;

    img {
      margin-right: 10px;
    }

    .header-title-text {
      font-style: normal;

      .brand-text {
        font-weight: normal;
        font-size: 14px;
        line-height: 15px;
        color: $ui-secondary;
      }

      .product-text {
        font-weight: bold;
        font-size: 16px;
        line-height: 18px;
        color: $ui-main;
      }
    }
  }

  .header-controls {
    display: flex;
    flex-direction: row;
    align-items: center;

    .header-button {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 8px 16px;
      height: 40px;
      box-sizing: border-box;
      border-radius: 20px;
      background-color: $brand-primary;
      color: $ui-main-background;
      cursor: pointer;

      .header-wallet-icon {
        height: 24px;
        width: 24px;
      }

      .header-arrow-icon {
        height: 24px;
        width: 12px;
        margin-left: 5px;
      }
    }

    .header-button.connected {
      cursor: default;
    }

    .header-address {
      display: flex;
      align-items: center;

      padding-left: 16px;
      border-radius: 20px;
      color: $brand-primary;
      background-color: $ui-web-background;

      &-text {
        padding-right: 10px;
      }
    }

    & > div {
      margin-left: 24px;

      &:first-child {
        margin-left: 0;
      }
    }
  }
}

.web3modal-modal-lightbox {
  z-index: 3 !important;
}
.web3modal-modal-card > div:not(:first-child) {
  display: none !important;
}
