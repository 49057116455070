.badge {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  padding: 2px 3px;
  border-radius: 4px;
  height: 17px;
  box-sizing: border-box;

  img {
    margin-right: 4px;
    height: 12px;
    display: inline-flex;
    margin-top: -1px;
  }

  &--failed {
    background: $attention-alarm;
    color: $text-dark-background-color;
  }
  &--finished {
    background: $ui-key;
    color: $ui-main;
  }
  &--awaiting {
    background: $ui-secondary;
    color: $text-dark-background-color;
  }
  &--approved {
    background: $ui-key;
    color: $ui-main;
  }
  &--declined {
    background: $attention-alarm;
    color: $text-dark-background-color;
  }
  &--expired {
    background: $ui-secondary;
    color: $text-dark-background-color;
  }
}
