.pool-expiration {
  .pool-expiration-text {
    justify-content: flex-end;
  }

  .pool-expiration-time-date {
    font-weight: bold;
    font-size: 19px;
    margin-right: 8px;
  }

  .pool-expiration-time-time {
    color: $ui-secondary;
    font-size: 19px;
  }
}
