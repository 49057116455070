.pool-asset-amount {
  padding: 6.5px 0;

  .pool-asset-amount-number {
    font-weight: bold;
    font-size: 19px;
    margin-right: 8px;
  }

  .pool-asset-amount-symbol {
    font-size: 19px;
    margin-left: 8px;
    color: $ui-border;
    font-weight: normal;
  }

  .pool-asset-coin-icon {
    width: 18px;
    height: 18px;
  }

  &.pool-asset-amount-large {
    .pool-asset-amount-number {
      font-size: 24px;
    }

    .pool-asset-amount-symbol {
      font-size: 24px;
    }
  }
}
