.dark {
  /* colors */
  --brand-primary: #5466FD;
  --brand-hover: #4256FF;
  --brand-secondary: #7A87F6;
  --brand-thirtiary: #373D74;
  --brand-element: #3956BE;
  --brand-background: #32376C;

  --ui-main: #FBFBFB;
  --ui-secondary: #9A98A9;
  --ui-border: #8A899A;
  --ui-el-background: #565562;
  --ui-inactive: #5F5E6E;
  --ui-main-background: #4D4C57;
  --ui-web-background: #35343C;
  --ui-seperator: #7A788C;
  --ui-neutral: #7A788C;
  --ui-contrast: #646374;
  --ui-key: #7A788C;
  --ui-main-selected: #4F4D5B;
  --ui-element-secondary: #605F6D;
  --ui-outline: #676675;
  --ui-solid: #ffffff;
  --ui-hoover: rgba(162, 164, 180, 0,15);
  --ui-paragraph: #000000;

  --attention-alarm: #FF3877;
  --attention-alarm-hover: #FA5A8D;
  --attention-alarm-border: #E584A3;
  --attention-alarm-background: #684053;
  --attention-alarm-inactive: #91435C;
  --attention-warning: #FAD30B;
  --attention-warning-background: #FDF8DC;
  --attention-neutral: #AF5570;
  --attention-positive: #95F972;

  --dark-tile-background: #fbfbfb;
  --blue-tile-background: #6587ff;
  --white-tile-background: #404068;

  --text-dark-background-color: #ffffff;
  --tab-menu-color: #9fa0a8;
  --tab-menu-active-color: #ffffff;

  /* effects */
  --top-bar-shadow: 0px 8px 22px rgba(19, 20, 28, 0.4);
  --main-window-shadow: 0px 8px 24px rgba(34, 35, 47, 0.8);
  --selected-window-shadow: 0px 18px 41px rgba(32, 34, 44, 0.79);

  --popover-background: rgba(77, 76, 87, 1);
  --popover-shadow: 0px 4px 21px rgba(34, 37, 51, 0.81);

  --popover-dark-background: rgba(77, 76, 87, 1);
  --popover-dark-shadow: 0px 7px 24px rgba(13, 18, 28, 0.34);

  --input-field-background: rgba(77, 76, 87, 1);
  --input-field-background-bright: rgba(77, 76, 87, 1);

  --dark-tile-shadow: 0px 7px 19px rgba(11, 12, 14, 0.52);
  --blue-tile-shadow: 0px 7px 19px rgba(22, 41, 199, 0.52);
  --white-tile-shadow: 0px 8px 24px rgba(45, 46, 53, 0.8);
  --menu-button-shadow: 0px 0px 8px rgba(26, 50, 250, 0.16), inset 0px 4px 4px rgba(2, 20, 171, 0.01);

  --dark-tile-hover-shadow: 0px 7px 22px rgba(13, 14, 17, 0.62);
  --blue-tile-hover-shadow: 0px 7px 22px rgba(28, 44, 194, 0.62);
  --white-tile-hover-shadow: 0px 8px 24px rgba(45, 46, 53, 0.8);

  --blue-shadow: 0px 7px 28px rgba(31, 51, 216, 0.57);

  --filter-sidebarmenuitem-img: brightness(0) invert(1);
  --filter-sidebarmenuitem-span: brightness(0) invert(1);
  --filter-sidebarmenuitem-img-invert: brightness(0);
}