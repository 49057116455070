.liquidity-mining-pool {
  margin: 30px 0;

  .pool-asset-exchange {
    font-weight: bold;

    .pool-asset-coin-icon {
      width: 18px;
      height: 18px;
    }

    img {
      margin-right: 4px;
    }
  }

  .pool-asset-exchange-separator {
    margin: 0 4px;
  }

  .pool-header {
    position: relative;
    z-index: 3;
    margin-bottom: -22px;

    .pool-header-content {
      background: $ui-el-background;
      box-shadow: $top-bar-shadow;
      border-radius: 61px;
      padding: 2px 24px 2px 2px;

      .pool-header-assets {
        background: $ui-inactive;
        border-radius: 61px;
        padding: 10px 16px;
        margin-right: 16px;

        .pool-asset-coin-icon {
          width: 18px;
          height: 18px;
        }
      }

      .pool-protocol {
        font-weight: bold;
        text-transform: capitalize;

        img {
          margin-right: 8px;
        }
      }
    }
  }

  .pool-content {
    border-radius: 16px;
    border: 1px solid $ui-inactive;
    overflow: hidden;

    .pool-content-header {
      font-weight: bold;
      font-size: 14px;
      color: $ui-secondary;
      text-transform: uppercase;

      img {
        filter: brightness(0) saturate(100%) invert(62%) sepia(7%) saturate(820%) hue-rotate(194deg) brightness(96%)
          contrast(84%);
        width: 18px;
        height: 18px;
        margin-left: 6px;
      }
    }

    .pool-assets {
      background: $ui-el-background;
      padding: 32px;
      box-shadow: $menu-button-shadow;
      position: relative;
      z-index: 2;

      .pool-version {
        display: flex;
        justify-content: center;
        font-weight: bold;
        color: $ui-secondary;
        font-size: 14px;
        margin-top: 10px;
      }
      .pool-address {
        text-align: center;
        font-weight: bold;
        color: $ui-secondary;
        font-size: 14px;
        margin-bottom: -20px;
      }
      .pool-name {
        text-align: center;
        font-weight: bold;
        color: $ui-main;
        font-size: 24px;
        margin-bottom: 30px;
      }

      .pool-assets-info {
        display: flex;
        margin-top: 16px;

        .pool-content-header {
          margin-bottom: 9px;
        }

        .pool-assets-separator {
          background: $ui-key;
          flex: 0 0 1px;
          width: 1px;
        }
      }

      .pool-assets-buttons {
        justify-content: flex-end;
        margin-top: 38px;

        button {
          margin-left: 24px;
        }
      }
    }

    .pool-extension {
      margin-top: 25px;

      .pool-extension-text {
        justify-content: flex-end;
      }

      .pool-extension-duration {
        justify-content: flex-end;
        font-weight: bold;
        font-size: 19px;
      }
    }

    .pool-lp {
      background: $ui-element-secondary;
      padding: 34px 32px 24px 32px;
      position: relative;
      z-index: 0;

      .pool-lp-assets-text {
        margin-bottom: 10px;
      }

      .pool-lp-tokens-text {
        justify-content: flex-end;
        margin-bottom: 4px;
      }

      .pool-lp-separator {
        border-top: 1px dashed $ui-border;
        margin: 0 24px;
      }
    }

    .pool-expiration-time {
      justify-content: flex-end;
    }
  }

  .empty-pool-content {
    text-align: center;
    padding: 32px 0 16px 0;
  }

  .hyperlink-pool-address {
    vertical-align: middle;
  }
}
