.multisig {
  &-overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: $ui-inactive;
    opacity: 0.65;
    z-index: 9;
  }

  &-container {
    width: 100%;
    position: absolute;
    z-index: 10;

    &-content {
      margin-left: auto;
      margin-right: auto;
      height: 800px;
      width: 1100px;
      padding: 32px 56px 56px;
      box-sizing: border-box;

      border-bottom-left-radius: 20px;
      border-bottom-right-radius: 20px;
      background-color: $ui-main-background;
      box-shadow: $main-window-shadow;
      z-index: 10;

      .admins-review-reviewers {
        padding-bottom: 40px;
      }

      .multisig-header {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: space-between;
      }

      .multisig-button-container {
        display: flex;
        margin-top: auto;
        align-self: center;
        justify-content: center;

        button {
          margin-left: 40px;

          &:first-child {
            margin-left: 0;
          }
        }
      }

      .add-container {
        padding: 32px;
        box-sizing: border-box;

        &.scroll-container {
          overflow-y: scroll;
          overflow-x: hidden;
          padding-right: 15px;
          height: 530px;
        }
      }

      .list-container {
        padding-left: 32px;
        padding-right: 32px;
        margin-top: 32px;
        margin-bottom: 32px;
        margin-right: 32px;
        box-sizing: border-box;

        .list {
          padding: 0;
          margin: 0;
        }

        &.scroll-container {
          overflow-y: scroll;
          overflow-x: hidden;
          padding-right: 15px;
          height: 300px;
        }
      }
    }
  }
}