.chip {
  display: flex;
  align-items: center;
  justify-content: center;
  width: min-content;
  height: 40px;
  border-radius: 20px;
  background-color: $attention-alarm-background;
  padding: 0 10px 0 15px;
  cursor: pointer;

  img {
    width: 20px;
    height: 20px;
  }

  &-text {
    margin-left: 10px;
    margin-right: 10px;
    color: $attention-alarm;
  }

  &-badge {
    display: flex;
    align-items: center;
    justify-content: center;

    border-radius: 12px;
    height: 24px;
    min-width: 24px;
    background-color: $attention-alarm;

    span {
      padding-left: 5px;
      padding-right: 5px;
      font-weight: 700;
      color: $text-dark-background-color;
    }
  }
}
