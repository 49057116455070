.coingecko-error-message {
  margin-top: 0px;
  padding-bottom: 20px;
}
.autocomplete-suggestions {
  position: absolute;
  width: 100%;
  margin-top: 50px;
  min-height: 46px;
  border-radius: 12px;
  background: $ui-main-background;
  border: 1px solid $ui-border;
  z-index: 1;
  max-height: 200px;
  overflow: auto;
  top: 0;
  box-shadow: $main-window-shadow;
  div.suggestions-list {
    margin-top: 0px;

    div.suggestions-list-item {
      &:first-child {
        border-top: none;
      }
      border-top: 1px solid $ui-border;
      padding: 15px;
      font-size: 14px;
      cursor: pointer;
      &:hover {
        background: $ui-outline;
      }
    }
  }
}
