@mixin placeholder {
  ::-webkit-input-placeholder {
    @content;
  }
  :-moz-placeholder {
    @content;
  }
  ::-moz-placeholder {
    @content;
  }
  :-ms-input-placeholder {
    @content;
  }
}

input[type='datetime-local'] {
  position: relative;
}

input[type='datetime-local']::-webkit-calendar-picker-indicator {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  padding: 0;
  color: transparent;
  background: transparent;
}

.pool-form-locking-container {
  width: 100%;
}
.pool-form .pool-form-locking {
  @media only screen and (max-width: 1200px) {
    width: 120% !important;
  }
}
.textField {
  display: flex;
  flex-wrap: wrap;
  border-radius: 23px;
  position: relative;

  &--rightComponent {
    input.textField--input {
      padding-right: 42px !important;
      text-overflow: ellipsis;
    }
  }

  label {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    line-height: 12px;
    /* identical to box height, or 109% */

    display: flex;
    align-items: center;
    letter-spacing: 0.02em;

    /* UI / Neutral */

    color: $ui-neutral;
    padding: 0px 8px;
    position: absolute;
    top: -6px;
    left: 40px;
    background: $ui-solid;
    border-radius: 10px;
    padding-top: 2px;
  }

  input.textField--input,
  textarea.textField--textArea {
    display: flex;
    width: 100%;
    background: $ui-solid;
    border: 1px solid $ui-border;
    box-sizing: border-box;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 18px;
    border-radius: 23px;
    padding: 13px 18px;
    outline: none;
    background-repeat: no-repeat;
    background-position: 18px center;
    background-size: 20px;
    align-content: center;
    padding-left: 48px;

    &:placeholder-shown + label {
      display: none;
    }
  }
  textarea {
    &.textField--textArea {
      border: 12px solid $ui-solid;
      padding: 0 5px;
      height: 108px;
      resize: none;
    }
  }

  &.textArea {
    background: $ui-solid;
    border: 1px solid var(--ui-border);
  }

  .text-field-validation {
    color: $attention-alarm;
    padding-top: 4px;
    padding-left: 20px;
    position: absolute;
    bottom: -16px;
  }

  .text-field--rightComponent {
    position: absolute;
    display: flex;
    height: 100%;
    justify-items: flex-end;
    align-items: center;
    right: 13px;
    text-align: right;
  }

  @include placeholder {
    color: $ui-secondary;
  }
}
