.pool-campaign {
  background: $ui-element-secondary;
  padding: 34px 32px 24px 32px;
  position: relative;
  z-index: 0;

  .pool-campaign-separator {
    border-top: 1px dashed $ui-border;
    margin: 0 24px;
  }

  .pool-content-header {
    margin-bottom: 8px;
  }

  .pool-campaign-date {
    font-weight: bold;
    margin-bottom: 2px;
  }

  .pool-campaign-time {
    font-weight: bold;
    color: $ui-border;
  }

  .pool-campaign-end {
    text-align: right;
    margin-right: 40px;
  }
}
