.light {
  /* colors */
  --brand-primary: #5466fd;
  --brand-hover: #2d48d6;
  --brand-secondary: #bdccff;
  --brand-thirtiary: #eef6ff;
  --brand-element: #6587ff;
  --brand-background: #e5ebff;

  --ui-main: #484a57;
  --ui-secondary: #9296ad;
  --ui-border: #b3b7ce;
  --ui-el-background: #fcfcff;
  --ui-inactive: #f0f1fe;
  --ui-main-background: #ffffff;
  --ui-web-background: #f6f6fa;
  --ui-seperator: #f9f9fc;
  --ui-neutral: #d0d3e0;
  --ui-contrast: #676978;
  --ui-key: #e8e9f0;
  --ui-main-selected: #555765;
  --ui-element-secondary: #f6f6fe;
  --ui-outline: #e0e1eb;
  --ui-solid: #ffffff;
  --ui-hoover: rgba(148, 152, 176, 0,08);
  --ui-paragraph: #000000;

  --attention-alarm: #ed2f6a;
  --attention-alarm-hover: #cc3d6a;
  --attention-alarm-border: #e79bb3;
  --attention-alarm-background: #ffeef4;
  --attention-alarm-inactive: #f9dbe4;
  --attention-warning: #fad30b;
  --attention-warning-background: #fdf8dc;
  --attention-neutral: #e4b6c4;
  --attention-positive: #69d443;

  --dark-tile-background: #484a57;
  --blue-tile-background: #6587ff;
  --white-tile-background: #ffffff;

  --text-dark-background-color: #ffffff;
  --tab-menu-color: #9fa0a8;
  --tab-menu-active-color: #ffffff;

  /* effects */
  --top-bar-shadow: 0px 8px 22px rgba(229, 230, 242, 0.4);
  --main-window-shadow: 0px 8px 24px rgba(226, 227, 241, 0.8);
  --selected-window-shadow: 0px 18px 41px rgba(196, 198, 216, 0.43);

  --popover-background: rgba(73, 75, 87, 0.95);
  --popover-shadow: 0px 4px 21px rgba(45, 52, 85, 0.51);

  --popover-dark-background: rgba(48, 50, 64, 0.95);
  --popover-dark-shadow: 0px 7px 24px rgba(13, 18, 28, 0.34);

  --input-field-background: rgba(84, 86, 101, 0.15);
  --input-field-background-bright: rgba(217, 230, 240, 0.05);

  --dark-tile-shadow: 0px 7px 19px rgba(110, 112, 123, 0.52);
  --blue-tile-shadow: 0px 7px 19px rgba(84, 102, 253, 0.52);
  --white-tile-shadow: 0px 8px 24px rgba(233, 234, 244, 0.8);
  --menu-button-shadow: 0px 0px 8px rgba(26, 50, 250, 0.16), inset 0px 4px 4px rgba(2, 20, 171, 0.01);

  --dark-tile-hover-shadow: 0px 7px 22px rgba(70, 75, 110, 0.62);
  --blue-tile-hover-shadow: 0px 7px 22px rgba(71, 89, 240, 0.62);
  --white-tile-hover-shadow: 0px 8px 24px rgba(233, 234, 244, 0.8);

  --blue-shadow: 0px 7px 28px rgba(84, 102, 253, 0,57);

  --filter-sidebarmenuitem-img: brightness(0) saturate(100%) invert(26%) sepia(18%) saturate(409%) hue-rotate(194deg) brightness(95%) contrast(86%);
  --filter-sidebarmenuitem-span: brightness(0) saturate(100%) invert(97%) sepia(2%) saturate(3652%) hue-rotate(187deg) brightness(102%) contrast(88%);
  --filter-sidebarmenuitem-img-invert: brightness(0);
}