.notification-container {
  display: flex;
  flex-direction: column;
  padding: 10px 8px 25px;
  box-shadow: $selected-window-shadow;

  width: 600px; // ?>
  height: 700px; // ?>
  border-radius: 29px;
  background-color: $ui-main-background;

  .notification-header {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-bottom: $ui-inactive solid 1px;
    padding-bottom: 10px;

    h4 {
      margin: 0 0 0 32px;
      color: $ui-main;
    }

    .chip {
      margin-left: auto;
    }

    .notification-navigation {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      margin-left: 10px;

      img {
        height: 11px;
        width: 11px;
        padding: 5px 8px;
        cursor: pointer;
        filter: $filter-sidebarmenuitem-img;
      }

      .disabled-arrow {
        opacity: 0.5;
        cursor: default;
      }

      .arrow-separator {
        width: 1px;
        height: 24px;
        background-color: $ui-key;
      }

      .flip {
        transform: rotate(180deg);
      }
    }
  }

  .notification-content {
    display: flex;
    flex-direction: column;
    padding: 32px 32px 0;
    height: calc(100% - 64px);

    h5 {
      display: flex;
      align-items: center;
      color: $ui-main;
      margin: 0 0 15px;

      img {
        padding-right: 10px;
        filter: $filter-sidebarmenuitem-img;
      }
    }

    .info-text {
      color: $ui-secondary;
      font-weight: 400;
    }

    .tabs-view-container {
      margin-top: 24px;
      margin-bottom: 24px;

      .transaction-container {
        h6, h4 {
          margin: 0;
        }

        .sending-to {
          display: flex;
          align-items: flex-end;
          justify-content: space-between;

          margin-top: 10px;
          margin-bottom: 30px;

          .list-item-user--details-name {
            font-weight: bold;
          }

          h5 {
            margin-bottom: 0;
            max-width: 170px;
            text-align: right;

            img {
              padding-left: 5px;
              padding-right: 5px;
              height: 18px;
              filter: $filter-sidebarmenuitem-img;
            }
          }
        }

        .transaction-user {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-top: 6px;
          margin-bottom: 6px;

          .header-light-big {
            display: flex;
            align-items: center;
            color: $ui-secondary;

            img {
              height: 14px;
              width: 14px;
              margin-left: 10px;
              cursor: pointer;
            }
          }

          &-title {
            display: flex;
            align-items: center;
          }

          .avatar {
            height: 22px;
            width: 22px;
            margin-right: 10px;
            border-radius: 12px;
          }
        }

        .awaiting-info {
          margin-top: 40px;
        }
      }

      .info-container {
        display: flex;
        flex-direction: column;

        .info-row {
          display: flex;
          justify-content: space-between;
          align-items: center;

          span {
            padding-top: 8px;
            padding-bottom: 8px;
            color: $ui-main;
          }
        }
      }
    }

    .buttons-container {
      padding-top: 30px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      .cancel-button {
        margin-right: 30px;
      }

      button:first-child {
        margin-right: 30px;
      }
    }
  }
}
