@import "./GraphButtonItem";

.graph-button {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  width:100%;
  margin-top: 60px;
}
.graph-placeholder {
	display: block;
	margin-top: 30px;
	width:100%;
}
