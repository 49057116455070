.notification-dropdown-container {
  position: relative;

  .visible {
    display: block;
  }

  &-content {
    display: none;
    left: -472px;
    position: absolute;
    top: calc(100% - 50px);
    z-index: 9999;
  }
}