.account-card {
  display: flex;
  align-items: center;
  box-sizing: border-box;

  height: 62px;
  border-radius: 31px;
  border: 1px solid $ui-secondary;
  background-color: $ui-main-background;

  padding-left: 24px;
  padding-right: 24px;

  &-avatar {
    height: 24px;
    width: 24px;
    border-radius: 12px;
    background-color: $ui-main;

    img {
      height: 24px;
      width: 24px;
      border-radius: 12px;
    }
  }

  &-text {
    margin-left: 10px;

    .header-large {
      line-height: 19px;
    }

    .header-light-big {
      color: $ui-secondary;
    }
  }

  &-rightComponent {
    margin-left: auto;
    letter-spacing: -0.03em;
  }

  .address-row {
    display: flex;
    align-items: flex-start;

    a {
      display: flex;
      padding-top: 2px;
      padding-left: 15px;
    }
  }
}
