.tab-menu .tab-menu-item {
  padding: 8.5px 14px;
  color: $tab-menu-color;
  font-weight: bold;
  font-size: 13px;
  line-height: 15px;

  transition: background-color 0.3s, box-shadow 0.3s, color 0.3s;
  border-radius: 16px;
  text-decoration: none;

  &.active {
    background-color: $brand-primary;
    color: $tab-menu-active-color;
    box-shadow: $blue-shadow;
  }
  &.disabled {
    color: $ui-inactive;
    cursor: default;
  }
}
