@import './constants';
@import './common';
@import './components';
@import './containers';
@import './pages';

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $ui-web-background;
}

#root {
  display: flex;
  flex-direction: column;
  /*height: 100vh;*/
  min-height: 100vh;
}
