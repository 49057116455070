.staking-card-title {
  .staking-title {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
    .info-icon {
      height: 20px;
      width: 20px;
      filter: $filter-sidebarmenuitem-img;
    }
    .refresh-icon {
      cursor: pointer;
    }
    .col {
      display: flex;
    }
  }
}
