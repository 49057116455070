.staking-pool {
  margin: 30px 0;

  .pool-asset-exchange {
    font-weight: bold;

    img {
      margin-right: 4px;
      width: 18px;
      height: 18px;
    }
  }

  .pool-asset-exchange-separator {
    margin: 0 4px;
  }

  .pool-header {
    position: relative;
    z-index: 3;
    margin-bottom: -22px;

    .pool-header-content {
      background: $ui-el-background;
      box-shadow: $top-bar-shadow;
      border-radius: 61px;
      padding: 2px;

      .pool-header-assets {
        background: $ui-inactive;
        border-radius: 61px;
        padding: 10px 16px;
      }
    }
  }

  .pool-content {
    border-radius: 16px;
    border: 1px solid $ui-inactive;
    overflow: hidden;
    .pool-expiration {
      .pool-expiration-text {
        justify-content: flex-start;
      }
    }
    .pool-content-header {
      font-weight: bold;
      font-size: 14px;
      color: $ui-secondary;
      text-transform: uppercase;

      img {
        filter: brightness(0) saturate(100%) invert(62%) sepia(7%) saturate(820%) hue-rotate(194deg) brightness(96%)
          contrast(84%);
        width: 18px;
        height: 18px;
        margin-left: 6px;
      }
    }

    .pool-info {
      background: $ui-el-background;
      padding: 32px;
      box-shadow: $menu-button-shadow;
      position: relative;
      z-index: 2;

      .pool-version {
        display: flex;
        justify-content: center;
        font-weight: bold;
        color: $ui-secondary;
        font-size: 14px;
        margin-top: 10px;
      }
      .pool-address {
        text-align: center;
        font-weight: bold;
        color: $ui-secondary;
        font-size: 14px;
        margin-bottom: -20px;
      }
      .pool-name {
        text-align: center;
        font-weight: bold;
        color: $ui-main;
        font-size: 30px;
        margin-bottom: 30px;
      }

      .pool-content-header {
        margin-bottom: 9px;
      }

      .pool-info-divider {
        height: 1px;
        background: $ui-key;
        margin: 32px 8px;
      }

      .pool-info-stats {
        align-items: flex-start;
        margin-top: 16px;

        .pool-asset-amount:not(:last-child) {
          margin-bottom: 25px;
        }

        .pool-info-limit {
          .pool-asset-amount {
            justify-content: flex-start;
          }
        }
      }

      .pool-info-locking {
        display: flex;

        .pool-info-separator {
          background: $ui-key;
          flex: 0 0 1px;
          width: 1px;
        }
        .pool-expiration-time {
          justify-content: flex-start;
        }
      }

      .pool-info-lockup {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;

        .pool-content-header {
          margin-bottom: 4px;
          display: flex;
          margin-top: 24px;
          &:first-child {
            margin-top: 0px;
          }
        }
      }
      .pool-info-lockup-duration {
        font-weight: bold;
        font-size: 19px;
      }

      .pool-info-lockup-divider {
        width: 1px;
        height: 32px;
        background: $ui-key;
        margin: 8px 24px 0 63px;
      }

      .pool-exit-round-duration {
        font-weight: bold;
        font-size: 19px;
        margin-bottom: 25px;
      }
    }
  }

  .hyperlink-pool-address {
    vertical-align: middle;
  }

  .whitelisted-campaigns {
    overflow-y: auto;
    max-height: 200px;
  }
}

.percentage-vertical-bar {
  display: flex;
  height: 145px;
  width: 12px;
  background-color: var(--ui-key);
  border-radius: 12px;
  overflow: hidden;
  margin-left: 30px;
  transform: rotate(180deg);
  .percentage-progress {
    width: 100%;
    background-color: linear-gradient(#3046ff, #e440ff);
    &-max {
      background: var(--attention-alarm);
    }
  }
}
.max-limit {
  color: 'red';
}
