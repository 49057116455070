.transactions-title {
  display: flex;
  justify-content: space-between;

  .input-container {
    display: flex;
    align-items: center;

    .info-icon {
      height: 20px;
      width: 20px;
      margin-left: 10px;
    }
  }
}