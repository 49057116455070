@import '../../../constants/colors.scss';

.manage-payment {
  .wrappedSwitchContainer {
    justify-content: space-between;
    .toggleSwitch {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 14px;
      gap: 5px;
      > span {
        width: 45px;
      }
      &.checked-false {
        span {
          &:first-child {
            font-weight: bold;
          }
        }
      }

      &.checked-true {
        span {
          &:last-child {
            font-weight: bold;
          }
        }
      }
    }
  }
  .payment-cols {
    display: flex;
    gap: 48px;

    .panel {
      flex: 1;
    }
  }

  .payment-ends {
    .date {
      display: flex;
      align-items: center;
      gap: 8px;

      .date-spacer {
        height: 1px;
        margin: 0 8px;
        background: $ui-neutral;
        flex: 1 1;
      }
    }
  }
  .no-payment {
    color: $attention-alarm;
    border: 1px solid $attention-alarm-border;
    border-radius: 16px;
    background: $attention-alarm-background;
    padding: 16px;
    margin-top: 16px;
  }
}
