.admins-section {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
}
.admins-section-info {
  height: 20px;
  width: 20px;
  filter: $filter-sidebarmenuitem-img;
}

.admins-count {
  color: $brand-primary;
}

.admins-review-section {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;

  margin-top: 32px;
  padding-left: 32px;
  padding-right: 32px;

  h5 {
    margin: 0;
    color: $ui-secondary;
  }
}

.admins-review-reviewers {
  margin-top: 25px;
  padding-left: 32px;
  padding-right: 32px;
}

.admins-button-container {
  display: flex;
  margin-top: auto;
  align-self: flex-end;

  button {
    margin-left: 40px;

    &:first-child {
      margin-left: 0;
    }
  }
}

.small-avatar-list {
  display: flex;
  align-items: center;

  .admins-small-avatar-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 20px;
    width: 20px;
    background-color: $ui-main;
    border-radius: 10px;
    margin-left: 5px;

    &:first-child {
      margin-left: 0;
    }

    .avatar-image {
      height: 42px;
      width: 42px;
      border-radius: 21px;
    }
  }
}

.admin-input-container {
  margin-top: 25px;
  padding: 32px;

  .admin-input-row {
    display: grid;
    grid-template-columns: 1fr 1.5fr 2fr auto;
    column-gap: 20px;
    align-items: center;
    padding-bottom: 20px;

    .delete-icon {
      cursor: pointer;
      padding: 10px;
      border-radius: 25px;
      filter: $filter-sidebarmenuitem-img;

      &:hover {
        filter: none;
        background-color: $ui-web-background;
      }
    }

    .delete-icon--disabled {
      padding: 10px;
      border-radius: 25px;
      opacity: 20%;
      filter: $filter-sidebarmenuitem-img;
    }

    .admin-input-badge {
      height: 14px;
      width: 14px;
      padding: 3px;
      border-radius: 10px;
    }

    .admin-input-good {
      background-color: $brand-primary;
    }

    .admin-input-bad {
      background-color: $attention-alarm;
    }
  }

  .admin-input-add {
    display: flex;
    justify-content: flex-end;
  }
}
