.overview-cards {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  .fund-card {
    margin-left: 20px;

    &:first-child {
      margin-left: 0;
    }
  }
}

.overview-title {
  display: flex;
  justify-content: space-between;

  .input-container {
    display: flex;
    align-items: center;

    .info-icon {
      height: 20px;
      width: 20px;
      margin-left: 10px;
      filter:$filter-sidebarmenuitem-img;
    }
  }
}

.overview-data {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 32px;
  background: $ui-el-background;
  border-radius: 16px;
}

.overview-label {
  color: $ui-secondary;
  padding-left: 25px;
  display: flex;
  margin-bottom: 5px;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 15px;
}

.overview-dropdown-container {
  .dropdown {
    min-width: 250px;
  }
}

.overview-protocol-choose-container {
  margin-top: 30px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  width: 100%;
}

.overview-subtitle {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;

  h4 {
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    color: $ui-secondary;
  }

  .input-container {
    display: flex;
    align-items: center;

    .info-icon {
      height: 20px;
      width: 20px;
      margin-left: 10px;
      filter:$filter-sidebarmenuitem-img
    }
  }
}

.apexcharts-xaxis text {
  fill: $ui-main;
}
.apexcharts-yaxis text {
  fill: $ui-main;
}
.apexcharts-gridlines-vertical line {
  stroke: $input-field-background;
}
.apexcharts-gridlines-horizontal line {
  stroke: $input-field-background;
}
.apexcharts-tooltip.apexcharts-theme-light {
  background: $ui-main-background;
  border:none !important;
  box-shadow: $main-window-shadow;
}
.apexcharts-tooltip.apexcharts-theme-light .apexcharts-tooltip-title {
  background: $ui-main-background !important;
  border:none !important;
  box-shadow: $main-window-shadow !important;
}
