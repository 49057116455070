.snackbar {
  position: fixed;
  right: 15px;
  top: 120px;
  z-index: 9;
  height: auto;
  width: 350px;
  box-sizing: border-box;
  border-radius: 8px;
  border-left: 12px solid;
  padding: 16px;
  -webkit-transition: .2s;
  transition: .2s;
  cursor:default;

  * {
    box-sizing: content-box !important;
  }
  &--hide {
    opacity: 0;
    z-index: -1;
  }
  &--info {
    background: $popover-background;
    border-color:$brand-element;
    h3 {
      color:$brand-element;
    }
    p {
      color:$ui-solid;
    }
  }
  &--error {
    background: $popover-background;
    border-color:$attention-alarm;
    h3 {
      color:$attention-alarm;
    }
    p {
      color:$ui-solid;
      padding-right: 30px;
    }
    span.close {
      color:$popover-background;
    }
  }
  span.close {
    background:#ffffff;
    margin: 0;
    padding: 0;
    display: inline-block;
    padding: 2px;
    line-height: 14px;
    height: 17px;
    border-radius: 50%;
    text-align: center;
    font-size: 20px;
    cursor: pointer;
    opacity: 0.8;
    width: 18px;
    &:hover {
      opacity: 1;
    }
  }
  > div {
    display: flex;
    align-content:center;
    align-items:center;
    justify-content: space-between;
    div {
          max-width: 90%;
    }
    h3 {
      margin: 1px 0px;
      font-family: Roboto;
      font-style: normal;
      font-weight: bold;
      font-size: 13px;
      line-height: 15px;
    }
    p {
      margin: 1px 0px;
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 16px;
    }
  }
}
