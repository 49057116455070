.container-inputs-layout {
  width: 100%;
  margin-bottom: 40px;
  .container-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    .info-icon {
      img {
        height: 14px;
        width: 14px;
        filter:$filter-sidebarmenuitem-img;
      }
    }
    .col {
      display: flex;
    }
  }
  .header-regular {
    color: $ui-secondary;
  }
}
