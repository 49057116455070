.onboarding {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;

  * {
    box-sizing: border-box;
    min-width: 0;
  }

  .onboarding-graphic {
    margin-right: 44px;
  }

  .onboarding-form {
    background: $ui-main-background;
    box-shadow: $main-window-shadow;
    border-radius: 20px;
    width: 500px;
    height: auto;
    padding: 56px;
    display: flex;
    flex-direction: column;

    .onboarding-header {
      font-weight: bold;
      font-size: 24px;

      display: flex;
      align-items: center;
    }

    .onboarding-info {
      font-size: 14px;
      margin: 24px 0 40px 0;
    }

    .onboarding-panel {
      background: $ui-el-background;
      border-radius: 16px;
      padding: 32px;
      margin-bottom: 30px;

      .textField:not(:last-child) {
        margin-bottom: 24px;
      }
    }

    .onboarding-buttons {
      display: flex;
      margin-top: 20px;

      button {
        flex: 1;
        min-width: 0;
      }
    }
  }
}

.steps {
  color: $ui-secondary;
  font-weight: bold;
  font-size: 13px;

  display: flex;
  align-items: center;

  .step-small {
    background: $brand-primary;
    opacity: 0.2;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-left: 9px;
  }

  .step-big {
    background: $brand-primary;
    border-radius: 8px;
    width: 34px;
    height: 10px;
    margin-left: 9px;
  }
}

.custom-error-message {
  padding-top: 0;
  margin-top: -24px;
}

.error-message-coingecko {
  border: var(--attention-alarm) 1px solid;
  font-size: smaller;
  border-radius: 8px;
  padding: 8px;
  margin-bottom: 10px;
  background-color: var(--attention-alarm-background);
}
