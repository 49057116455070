.popover {
  background: var(--popover-background);
  box-shadow: var(--popover-shadow);
  border-radius: 18px;
  color: var(--ui-neutral);
  font-size: 14px;
  padding: 24px;
  position: fixed;
  width: 290px;
  text-transform: none !important;
  font-weight: 400 !important;
  h4 {
    font-size: 18px;
    margin: 0;
    margin-bottom: 15px;
  }
  img {
    filter: brightness(0) invert(1);
  }

  .popover-header {
    margin-bottom: 8px;
  }

  .popover-arrow {
    width: 0;
    height: 0;
    border-left: 6.5px solid transparent;
    border-right: 6.5px solid transparent;
    position: absolute;
    bottom: -9px;
    left: 165px;

    border-top: 9px solid var(--popover-background);
  }
}
