.assets-cards {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  .fund-card {
    margin-left: 20px;

    &:first-child {
      margin-left: 0;
    }
  }
}

.assets-title {
  display: flex;
  justify-content: space-between;

  .input-container {
    display: flex;
    align-items: center;

    .info-icon {
      height: 20px;
      width: 20px;
      margin-left: 10px;
    }
  }
}

.assets-list {
  box-sizing: border-box;
  height: 100%;
  color: $ui-main;

  &-header {
    color: $ui-secondary;
    height: 32px;
    align-items: center;
  }

  &-item {
    padding: 8px 0;
    margin-top: 15px;
    margin-bottom: 15px;
  }

  &-container {
    padding: 32px;
    height: 100%;
    box-sizing: border-box;

    .scroll-container {
      overflow-y: scroll;
      overflow-x: hidden;
      padding-right: 15px;
      height: calc(100vh - 707px);
    }
  }

  &-layout {
    display: grid;
    min-width: 100%;
    grid-template-columns: 3fr 1fr;
    padding: 0 8px;
    box-sizing: border-box;

    &-header {
      padding-left: 32px;
      padding-right: calc(32px + 15px + 4px); // default + additional + scroll width
    }

    &-token {
      display: flex;
      align-items: center;

      h5 {
        margin: 0;
      }

      &-icon {
        height: 24px;
        width: 24px;
        margin-right: 10px;
      }
    }

    &-amount {
      display: flex;
      align-items: baseline;
      justify-content: flex-end;

      &-coin {
        margin-right: 10px;
        color: $ui-secondary;
      }
    }

    .align-right {
      text-align: right;
    }
  }
}
