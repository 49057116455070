.add-icon-button {
  $buttonHeight: 30px;
  $borderRadius: 26px;

  height: $buttonHeight;
  width: 105px;
  background-color: $brand-primary;
  border: 1px solid $brand-primary;
  margin-left: 10px;
  transition: background-color 0.17s ease-in;
  border-radius: $borderRadius;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  &:hover {
    border-color: $brand-hover;
    background: $brand-hover;
  }

  &.value {
    background-color: initial;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: left;
    padding-left: 1rem;

    .label-container {
      color: $ui-main;
    }
  }

  .label-container {
    color: var(--ui-solid);
    cursor: pointer;
    width: 100%;
    text-align: center;
    border-radius: $borderRadius;
    line-height: $buttonHeight;

    &.disabled {
      color: $ui-border;
      cursor: not-allowed;
    }

    .void-div {
      height: $buttonHeight;
      width: 105px;
      cursor: pointer;
      border-radius: $borderRadius;
    }
    .label-text {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .circle {
        align-self: center;
        background: $ui-inactive;
        width: 102px;
        height: 102px;
        border-radius: 50%;
      }
      h6 {
        margin: 10px 0;
      }
    }
  }

  &.disabled {
    background: $ui-inactive;
    border-color: $ui-inactive;
    color: $ui-border;
    cursor: not-allowed;
  }

  input {
    display: none;
  }
}
