.dark_blue {
  /* colors */
  --brand-primary: #5466fd;
  --brand-hover: #7886fe;
  --brand-secondary: #4c5ad1;
  --brand-thirtiary: #373d74;
  --brand-element: #3956be;
  --brand-background: #32376c;

  --ui-main: #fbfbfb;
  --ui-secondary: #9fa3c1;
  --ui-border: #9093b2;
  --ui-el-background: #4a4a73;
  --ui-inactive: #5b5b86;
  --ui-main-background: #404068;
  --ui-web-background: #323252;
  --ui-seperator: #5e5e87;
  --ui-neutral: #7177a2;
  --ui-contrast: #5a6085;
  --ui-key: #7177a4;
  --ui-main-selected: #515469;
  --ui-element-secondary: #565686;
  --ui-outline: #646985;
  --ui-solid: #ffffff;
  --ui-hoover: rgba(145, 150, 179, 0,15);
  --ui-paragraph: #000000;

  --attention-alarm: #ff3877;
  --attention-alarm-hover: #fa5a8d;
  --attention-alarm-border: #e584a3;
  --attention-alarm-background: #6d3952;
  --attention-alarm-inactive: #92415b;
  --attention-warning: #fad30b;
  --attention-warning-background: #fdf8dc;
  --attention-neutral: #9f566c;
  --attention-positive: #95f972;

  --dark-tile-background: #fbfbfb;
  --blue-tile-background: #6587ff;
  --white-tile-background: #404068;

  --text-dark-background-color: #ffffff;
  --tab-menu-color: #9fa0a8;
  --tab-menu-active-color: #ffffff;

  /* effects */
  --top-bar-shadow: 0px 8px 22px rgba(26, 29, 53, 0.4);
  --main-window-shadow: 0px 8px 24px rgba(37, 39, 56, 0.8);
  --selected-window-shadow: 0px 18px 41px rgba(27, 29, 48, 0.79);

  --popover-background: rgba(73, 75, 87, 0.95);
  --popover-shadow: 0px 4px 21px rgba(30, 33, 49, 0.81);

  --popover-dark-background: rgba(48, 50, 64, 0.95);
  --popover-dark-shadow: 0px 7px 24px rgba(13, 17, 26, 0.34);

  --input-field-background: rgba(84, 86, 101, 0.15);
  --input-field-background-bright: rgba(217, 230, 240, 0.05);

  --dark-tile-shadow: 0px 7px 19px rgba(25, 27, 36, 0.52);
  --blue-tile-shadow: 0px 7px 19px rgba(25, 34, 105, 0.52);
  --white-tile-shadow: 0px 8px 24px rgba(37, 39, 55, 0.8);
  --menu-button-shadow: 0px 0px 8px rgba(26, 50, 250, 0.16), inset 0px 4px 4px rgba(2, 20, 171, 0.01);

  --dark-tile-hover-shadow: 0px 7px 22px rgba(25, 26, 39, 0.62);
  --blue-tile-hover-shadow: 0px 7px 22px rgba(25, 34, 105, 0.62);
  --white-tile-hover-shadow: 0px 8px 24px rgba(37, 39, 55, 0.8);

  --blue-shadow: 0px 7px 28px rgba(84, 102, 253, 0.57);

  --filter-sidebarmenuitem-img: brightness(0) invert(1);
  --filter-sidebarmenuitem-span: brightness(0) invert(1);
  --filter-sidebarmenuitem-img-invert: brightness(0);
}