.tooltip {
    position: relative;
    display: inline-flex;
  
    &::before {
      content: attr(data-tooltip);
      opacity: 0;
      position: absolute;
      background: $ui-main-background;
      border-radius: 5px;
      left: 50%;
      transform: translate(-50%, 50%);
      bottom: 50%;
      padding: 5px;
      text-align: center;
      color: $ui-main;
      font-size: 13px;
      width: 100%;
      min-width: 150px;
      max-height: 0px;
      transition: all 0.3s ease;
      overflow: hidden;
      margin: auto;
      line-height: 1.3em;
      pointer-events: none;
      z-index: 10;
    }
  
    &:hover::before {
      opacity: 1;
      max-height: 600px;
      bottom: 100%;
      transform: translate(-50%, -10px);
    }
  
    &.clipboard {
      &:hover::before {
        opacity: 0;
        max-height: 0px;
        bottom: 50%;
        transform: translate(-50%, 50%);
      }
  
      &.active::before {
        opacity: 1;
        max-height: 600px;
        bottom: 100%;
        transform: translate(-50%, -10px);
      }
    }
  }