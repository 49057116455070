@import "./TabMenuItem";

.tab-menu {
  display: inline-flex;
  flex-direction: row;
  box-shadow: $white-tile-shadow;
  background-color: $ui-main-background;
  border-radius: 25px;
  flex-wrap: wrap;
  height: 32px;
  padding: 0;
}
