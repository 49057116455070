.graph-button .graph-button-item {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: -0.01em;
  color: $ui-border;
  padding: 5px 16px;
  cursor:pointer;

  &.separator {
    flex-grow: 1;
  }

  &:hover {
    color:$ui-secondary;
  }

  &.active {
    background: $brand-primary;
    border-radius: 26px;
    color: $ui-main-background;
  }


}
