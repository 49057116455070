.list-item-user {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  &--left {
      display: flex;
      flex-direction: row;
  }

  &--right {
      display: flex;
      flex-direction: row;
      align-content: center;
  }

  .avatar-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 42px;
    width: 42px;
    background-color: $ui-main;
    border-radius: 50%;
    margin-right: 8px;

    .avatar-image {
      height: 42px;
      width: 42px;
      border-radius: 50%;
      background-color: $ui-main;
      box-shadow: $main-window-shadow;
    }
  }

  &--details {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-left: 8px;

    &--address {
      display: flex;
      flex-direction: row;
    }

    .controls {
      margin-left: 15px;
    }

    .user-address {
      color: $ui-secondary;
    }
  }

  .controls {
    height: min-content;
    align-self: center;

    img {
      filter: $filter-sidebarmenuitem-img;
    }

    > * {
        cursor: pointer;
    }

    > :last-child {
      margin-left: 12px;
    }
  }
}
