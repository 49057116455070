@mixin placeholder {
  ::-webkit-input-placeholder {
    @content;
  }
  :-moz-placeholder {
    @content;
  }
  ::-moz-placeholder {
    @content;
  }
  :-ms-input-placeholder {
    @content;
  }
}
.token-input-field-container {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  .col:nth-child(1) {
    flex: 1;
  }
  .col:nth-child(2) {
    flex: 0 0 15%;
    width: 15%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .textField {
    display: flex;
    flex-wrap: wrap;
    border-radius: 23px;
    position: relative;
    span {
      position: absolute;
      left: 49px;
      top: 13px;
      color: $ui-secondary;
    }
    label {
      font-family: Roboto;
      font-style: normal;
      font-weight: 500;
      font-size: 11px;
      line-height: 12px;
      /* identical to box height, or 109% */

      display: flex;
      align-items: center;
      letter-spacing: 0.02em;

      /* UI / Neutral */

      color: $ui-neutral;
      padding: 0px 8px;
      position: absolute;
      top: -6px;
      left: 40px;
      background: $ui-solid;
      border-radius: 10px;
      padding-top: 2px;
    }
    input.textField--input {
      display: flex;
      width: 100%;
      background: $ui-solid;
      border: 1px solid $ui-border;
      box-sizing: border-box;
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 18px;
      border-radius: 23px;
      padding: 13px 18px;
      outline: none;
      background-repeat: no-repeat;
      background-position: 18px center;
      background-size: 20px;
      align-content: center;
      padding-left: 48px;
      &.token {
        padding-left: 120px;
        &[readOnly] {
          opacity: 0.8;
          cursor: default;
        }
      }
      &:placeholder-shown + label {
        display: none;
      }
    }

    @include placeholder {
      color: $ui-secondary;
    }
  }
}
