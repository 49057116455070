.coin-chain {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  > img:first-child {
      display:none;
    }
    > img {
      margin:0 10px;
      margin-bottom: 5px;
    }
  span {
    display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  margin-bottom: 5px;

    img {
      width: 18px;
      margin-right: 6px;
    }
    strong {
      font-family: Roboto Condensed;
      font-style: normal;
      font-weight: bold;
      font-size: 13px;
      line-height: 15px;
      letter-spacing: -0.01em;
      color: $ui-main;
    }
  }
}