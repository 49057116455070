.modern-stepper {
  padding: 16px;

  .modern-step {
    .modern-step-head {
      display: flex;
      align-items: center;

      .modern-step-number {
        flex: 0 0 24px;
        width: 24px;
        height: 24px;
        border-radius: 50%;
        background: $ui-main;
        color: $ui-main-background;
        font-size: 13px;
        margin-right: 25px;
        margin-left: 9px;

        display: flex;
        align-items: center;
        justify-content: center;
      }

      .modern-step-header {
        flex: 1;
        font-size: 14px;

        .modern-step-title {
          font-weight: bold;
        }

        .modern-step-subtitle {
          margin-top: 5px;
          display: none;
        }

        .modern-step-check-icon {
        }
      }
    }

    .modern-step-body {
      display: flex;

      .modern-step-line {
        border-right: 1px dotted #b3b7ce;
        margin-top: 10px;
        margin-bottom: 10px;
        margin-left: 21px;
        margin-right: 28px;
      }

      .modern-step-content {
        flex: 1;

        .modern-step-subtitle {
          font-size: 14px;
          margin-top: 8px;

          display: none;
        }

        .modern-step-panel {
          font-size: 14px;
          background: $ui-el-background;
          border-radius: 16px;
          padding: 20px 16px;
          margin-top: 32px;

          display: none;
        }

        .modern-step-buttons {
          margin-top: 32px;

          display: none;
          justify-content: space-between;
          button:last-child {
            margin-left: auto;
          }
        }

        .modern-step-divider {
          border-top: 1px solid $ui-neutral;
          margin: 20px 32px;
        }
      }
    }

    &.modern-step-active {
      @media only screen and (min-width: 600px) {
        .modern-step-number {
          flex: 0 0 42px;
          width: 42px;
          height: 42px;
          margin-left: 0px;
          margin-right: 16px;
        }

        .modern-step-head .modern-step-header .modern-step-subtitle {
          display: block;
        }
      }

      .modern-step-content .modern-step-panel {
        display: block;
      }

      .modern-step-content .modern-step-buttons {
        display: flex;
      }
    }

    &:last-child {
      .modern-step-line,
      .modern-step-divider {
        display: none;
      }

      @media only screen and (min-width: 600px) {
        .modern-step-body .modern-step-content {
          margin-left: 58px;
        }
      }
    }

    &.modern-step-away {
      @media only screen and (max-width: 600px) {
        margin-bottom: 16px;
      }

      .modern-step-divider {
        display: none;
      }
    }

    @media only screen and (max-width: 600px) {
      .modern-step-head {
        .modern-step-number {
          margin-right: 16px;
          margin-left: 0px;
        }
      }

      &.modern-step-active {
        .modern-step-body {
          .modern-step-content {
            .modern-step-subtitle {
              display: block;
            }
          }
        }
      }

      .modern-step-line {
        display: none;
      }
    }
  }
}
