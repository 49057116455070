@import '../../../../../constants/colors.scss';

.sub-icon {
  height: 34px;
  width: 34px;
  background: $ui-main;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}