.switch {
  position: relative;
  display: inline-block;
  width: 43px;
  height: 26px;

  input {
    opacity: 0;
    width: 0;
    height: 0;
  }
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $ui-key;
  -webkit-transition: .4s;
  transition: .4s;

  &:before {
    position: absolute;
    content: "";
    height: 24px;
    width: 24px;
    left: 1px;
    bottom: 1px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }
}

input:checked + .slider {
  background-color: $brand-primary;
}

input:focus + .slider {
  box-shadow: 0 0 1px $brand-primary;
}

input:checked + .slider:before {
  transform: translatex(17px);
}

.slider.round {
  border-radius: 26px;

  &:before {
    border-radius: 50%;
  }
}
