.fund-card {
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  width: 220px;
  color: $ui-main-background;
  background-color: $brand-primary;

  .fund-coin {
    display: flex;
    align-items: center;
    padding: 16px 16px 8px;

    &-icon {
      height: 18px;
      width: 18px;
      margin-right: 5px;
    }
  }

  .fund-value {
    align-self: flex-end;
    padding: 8px 16px 8px 8px;
  }
}