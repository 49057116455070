@import "./SidebarMenuItem";

.sidebar-menu {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  min-width: 320px;
  padding: 30px 40px 56px;
  box-sizing:border-box;
  position: relative;
  h2 {
    margin: 0 0 32px;
	 color: $ui-secondary;
  }
  .sidebar-menu-disable-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    background: #000;
    left: 0;
    top: 0;
    z-index: 1;
    opacity: 0;
  }
}
