.sidebar-menu .sidebar-menu-item {
  padding-left: 17px;
  padding-right: 17px;
  padding-top: 14.5px;
  padding-bottom: 14.5px;

  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  color: $ui-main;

  line-height: 15px;
  text-decoration: none;

  transition: background-color 0.3s, box-shadow 0.3s, color 0.3s;
  border-radius: 16px;
  display: flex;
  align-content: center;
  align-items: center;

  &.active {
    background-color: $ui-inactive;
    color: $ui-main;
    span {
      filter: none;
    }
  }
  img {
    margin-right: 9px;
    height: 15px;
    filter: $filter-sidebarmenuitem-img;
  }
  span {
    width: 16px;
    height: 16px;
    display: flex;
    background-repeat: no-repeat;
    background-position: center right;
    flex-grow: 1;
    filter: $filter-sidebarmenuitem-span
  }
}
