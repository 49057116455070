.pool-form-header {
  color: $ui-secondary;
  font-weight: bold;
  font-size: 14px;
  margin: 16px 0;
  justify-content: space-between;
  &.exit-round-duration {
    display: flex;
    justify-content: flex-start;
    gap: 5px;
    align-items: center;
    &.split {
      > div {
        width: 100%;
        display: flex;
        align-items: center;
        gap: 5px;
      }
    }
  }
  .popover-info {
    height: 20px;
    width: 20px;
    filter: $filter-sidebarmenuitem-img;
    vertical-align: middle;
  }
  .toggleSwitch {
    display: flex;
    justify-content: space-between;
    text-align: center;
    &.checked-false {
      > span:last-child {
        color: $ui-inactive;
      }
    }
    &.checked-true {
      > span:first-child {
        color: $ui-inactive;
      }
    }
    > span {
      line-height: 24px;
      margin: 0px 10px;
      cursor: pointer;
    }
  }
  & + .pool-form-reward {
    margin-top: 0;
  }
}

.pool-form-copy-factory {
  cursor: pointer;
  margin-left: 10px;
}

.pool-form-locking {
  width: 100%;

  th {
    text-align: left;
    font-weight: bold;
    font-size: 14px;
    color: $ui-secondary;
    padding-bottom: 8px;
  }

  tr {
    padding-bottom: 8px;
  }

  .textfield {
    flex: 1;
  }

  input {
    padding: 8px 12px;
    padding-left: 48px;
    margin-right: 10px;
  }

  .dropdown {
    height: 36px;
    padding-left: 12px;
    padding-right: 12px;
    width: 140px;
    margin-right: 10px;

    .dropdown-content {
      padding: 0;
      .scroll-container {
        padding-right: 0;
      }
    }

    .header-light-large {
      font-size: 14px;
    }
  }

  .pool-form-locking-remove {
    cursor: pointer;
    margin-top: 4px;
    filter: $filter-sidebarmenuitem-img;
  }
}

.pool-form-locking-add {
  margin-top: 20px;
}

.pool-form-campaign-duration {
  display: flex;
  &.split {
    display: flex;
    gap: 20px;
    > div {
      flex: initial;
      width: 100% !important;
    }
  }
  &.exit-duration {
    .textField {
      margin-right: 0;
    }
    .dropdown {
      display: none;
    }
  }

  .textField {
    flex: 1;
    margin-right: 20px;
  }

  &.localtime-field {
    .textField {
      margin-right: 0;
    }
  }

  .dropdown {
    width: 200px;
  }
}
.pool-form-throttle-duration {
  margin-bottom: 20px;

  .textField {
    flex: 1;
    margin-right: 20px;
  }

  .dropdown {
    width: 200px;
  }
}
.pool-form-reward {
  margin-top: 24px;

  .customError + .token-input-field-container {
    margin-bottom: 24px;
  }

  .textfield:not(:first-child) {
    width: 300px;
  }
}

.coingecko-input-field {
  margin-bottom: 20px;
  margin-top: 20px;

  .textfield:not(:first-child) {
    width: 300px;
  }
}

.pool-form-rewards-add {
  display: flex;
  justify-content: center;

  button {
    margin-right: 20px;
  }
}

.pool-form-no-balance {
  margin-bottom: 20px;
  text-align: center;
}

.text-field-validation {
  color: $attention-alarm;
  padding-left: 20px;

  &.customError {
    display: block;
    height: 16px;
    margin-bottom: 10px;
  }
}

.pool-form-error {
  color: $attention-alarm;
  border: 1px solid $attention-alarm-border;
  border-radius: 16px;
  background: $attention-alarm-background;
  padding: 16px;
  margin-top: 16px;
  font-weight: 500;
  a {
    font-weight: bold;
    color: $attention-alarm;
  }
}

.pool-form-info {
  margin-top: 40px;
  margin-bottom: -20px;
  text-align: center;
  font-weight: bold;
  line-height: 1.4;
  .copy-icon {
    vertical-align: middle;
    cursor: pointer;
  }
}

.pool-form-summary {
  .dropdown,
  input {
    cursor: default;
    background-color: $ui-inactive !important;
    color: var(--ui-secondary) !important;
    border: none !important;

    .dropdown-rightComponent {
      display: none;
    }
  }
  .textField.textArea {
    border: none;
    textarea {
      border-width: 13px;
      cursor: default;
      background-color: $ui-inactive !important;
      border-color: $ui-inactive !important;
      color: var(--ui-secondary) !important;
    }
    background-color: $ui-inactive;
  }

  .toggleSwitch {
    input:checked + .slider {
      background-color: $ui-inactive !important;
    }
  }

  .coingecko-input-field,
  .token-input-field-container {
    .col + .col {
      display: none;
    }
  }
}

.lpAddressFinderDropdown {
  margin-bottom: 20px;
}
.lpAddressFinder {
  margin-bottom: 20px;
}
.w-100 {
  width: 100%;
}

.pool-form-warning {
  color: $attention-alarm;
  margin-top: 20px;
  text-align: center;
  font-weight: bold;
}

.pool-form-align-right {
  display: flex;
  justify-content: right;

  button {
    margin-top: 40px;
  }
}

.modern-step-panel .rewards-calculator-form {
  margin-left: -16px;
  margin-right: -16px;
}

.rewards-calculator-form {
  background-color: $ui-web-background;
  padding: 18px 14px 24px;
  box-shadow: 0 4px 10px -4px rgba(215, 215, 215, 0.79) inset;

  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }
  input[type='number'] {
    -moz-appearance: textfield;
  }

  .title {
    color: $ui-secondary;
    font-weight: bold;
    font-size: 14px;
  }

  .line {
    border-top: 1px solid $ui-neutral;
    margin: 24px 34px 0;
  }

  .indicator {
    border-radius: 16px;
    box-shadow: $top-bar-shadow;
    background-color: $ui-main-background;
    padding: 16px 16px 16px;

    .title {
      color: $ui-secondary;
      font-weight: bold;
    }

    .spacer {
      padding: 64px;
    }
  }
}

.cooldown-warning-message {
  font-size: 16px;
  line-height: 1.5;
  color: $ui-contrast;
  border: 1px solid $attention-warning;
  background: $attention-warning-background;
  padding: 15px;
  border-radius: 8px;
  margin-bottom: 20px;
  margin-top: 15px;
}
.cooldown-alert-container {
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  .cooldown-alert-overlay {
    background: rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 100%;
    z-index: 9;
    position: absolute;
  }
  .cooldown-alert-content {
    top: 50%;
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    background: $ui-main-background;
    color: $ui-main;
    padding: 45px;
    box-shadow: $top-bar-shadow;
    border-radius: 12px;
    border: 1px solid $ui-inactive;
    z-index: 10;
    max-width: 600px;
    .cooldown-alert-title {
      font-size: 24px;
      font-weight: bold;
      margin-bottom: 10px;
    }
    .cooldown-alert-text {
      font-size: 18px;
    }
    .cooldown-alert-buttons {
      margin-top: 30px;
      display: flex;
      justify-content: space-between;
      flex-direction: row-reverse;
    }
  }
}

.exit-round-container {
  padding: 30px;
  box-sizing: border-box;
  margin-top: 30px;
  background-color: var(--ui-web-background);
  border-radius: 12px;
  border: 1px solid var(--ui-inactive);
  h4 {
    margin-top: 0px;
  }
}

.credits-title {
  margin-bottom: 0px;
  border-top: 1px solid $ui-neutral;
  padding-top: 30px;
  margin-top: 30px;
}
.credits-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 30px;
  border-bottom: 1px solid $ui-neutral;
  padding-bottom: 30px;
  margin-bottom: 30px;

  > div {
    color: $ui-main;
    background: $ui-web-background;
    text-align: center;
    border-radius: 16px;
    padding: 10px 0px;
    margin-top: 15px;
    width: 100%;
  }
}
.new-campaign-loading {
  margin-top: 100px;
  text-align: center;
}
