@import '../../constants/colors.scss';
.configuration {
  box-shadow: $main-window-shadow;
  border-radius: 20px;
  max-width: 632px;
  width: 100%;
  margin: 50px 0;
  background-color: $ui-main-background;
  padding: 56px;
  display: flex;
  flex-direction: column;

  &--content {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .pre-title-config {
      display: flex;
      justify-content: space-between;
    }
  }

  .available-tokens {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 10px;
    span,
    img {
      margin-left: 5px;
      &.alert {
        color: $attention-alarm;
      }
    }
  }

  .center-content {
    height: 70%;

    .action-container {
      width: 100%;
      min-height: 267px;
      border-radius: 16px;
      background-color: $ui-web-background;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  h3 {
    margin: 0px;
  }

  .next-button-container {
    display: flex;
    align-items: flex-end;
    margin-top: 25px;

    button {
      width: 100%;
    }
  }
}

.configuration-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.container-configuration-form {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  .text-field-container {
    margin-top: 15px;
    margin-bottom: 15px;
    .token-container {
      background-color: $ui-web-background;
      border-radius: 25px;
      padding: 30px;
      margin-bottom: 15px;
      > .textField {
        margin-bottom: 0;
      }
      .coingecko-input-field {
        margin-top: 20px;
        margin-bottom: 0;
        .textField {
          margin-bottom: 0;
        }
      }
      .text-field-validation {
        display: none;
      }
    }
    .inputPhoto {
      height: 100%;
      padding: 15px;
      box-sizing: border-box;
    }
    .textField {
      margin-bottom: 20px;
    }
  }
  .button-container {
    display: flex;
    justify-content: flex-end;
    .button--tertiary {
      img {
        transform: rotate(180deg);
      }
    }
  }
}
