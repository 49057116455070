body {
    font-family: 'Roboto';
    color: $ui-main;
}

.header-mega, h1 {
    font-weight: bold;
    font-size: 37px;
}

.header-huge, h2 {
    font-weight: bold;
    font-size: 30px;
}

.header-lead, h3 {
    font-weight: bold;
    font-size: 24px;
}

.header-large, h4 {
    font-weight: bold;
    font-size: 19px;
}

.header-big, h5 {
    font-weight: bold;
    font-size: 16px;
}

.header-regular, h6 {
    font-weight: bold;
    font-size: 14px;
}

.header-small {
    font-weight: bold;
    font-size: 13px;
}

.header-smallest {
    font-weight: 500;
    font-size: 11px;
}
.header-mega, h1 {
    font-weight: bold;
    font-size: 37px;
}

.header-huge, h2 {
    font-weight: bold;
    font-size: 30px;
}

.header-lead, h3 {
    font-weight: bold;
    font-size: 24px;
}

.header-large, h4 {
    font-weight: bold;
    font-size: 19px;
}

.header-big, h5 {
    font-weight: bold;
    font-size: 16px;
}

.header-regular, h6 {
    font-weight: bold;
    font-size: 14px;
}

.header-small {
    font-weight: bold;
    font-size: 13px;
}

.header-smallest {
    font-weight: 500;
    font-size: 11px;
}

.header-mega, h1 {
    font-weight: bold;
    font-size: 37px;
}

.header-huge, h2 {
    font-weight: bold;
    font-size: 30px;
}

.header-lead, h3 {
    font-weight: bold;
    font-size: 24px;
}

.header-large, h4 {
    font-weight: bold;
    font-size: 19px;
}

.header-big, h5 {
    font-weight: bold;
    font-size: 16px;
}

.header-regular, h6 {
    font-weight: bold;
    font-size: 14px;
}

.header-small {
    font-weight: bold;
    font-size: 13px;
}

.header-smallest {
    font-weight: 500;
    font-size: 11px;
}

.header-light-mega {
    font-size: 37px;
}

.header-light-huge {
    font-size: 30px;
}

.header-light-lead {
    font-size: 24px;
}

.header-light-large {
    font-size: 19px;
}

.header-light-big {
    font-size: 16px;
}

.header-light-regular {
    font-size: 14px;
}

.header-light-small {
    font-size: 13px;
}

.header-light-smallest {
    font-size: 11px;
}

.paragraph-large {
    font-size: 16px;
    line-height: 140%;
}

.paragraph-regular {
    font-size: 14px;
    line-height: 140%;
}

.paragraph-smallest {
    font-size: 12px;
    line-height: 140%;
}

.paragraph-bold-large {
    font-weight: 500;
    font-size: 16px;
    line-height: 140%;
}

.paragraph-bold-regular {
    font-weight: 500;
    font-size: 14px;
    line-height: 140%;
}

.paragraph-bold-small {
    font-weight: 500;
    font-size: 13px;
    line-height: 140%;
}

.button-text {
    font-style: normal;
    font-weight: bold;
}

.table-text {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0;
    text-align: left;
}

.card-value {
    font-size: 41px;
    line-height: 48px;
    font-weight: 700;
}
