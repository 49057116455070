@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@400;500;700&display=swap');

/**
 * Project wide utilities
 */

// Margin
.m-0 {
  margin: 0;
}

.mt-0 {
  margin-top: 0;
}

.mt-4 {
  margin-top: 4px;
}

.mt-8 {
  margin-top: 8px;
}

.mt-24 {
  margin-top: 24px;
}

.mt-48 {
  margin-top: 48px;
}

.ml-4 {
  margin-left: 4px;
}

.ml-8 {
  margin-left: 8px;
}

.ml-24 {
  margin-left: 24px;
}

.mr-4 {
  margin-right: 4px;
}

.mr-8 {
  margin-right: 8px;
}

.mr-24 {
  margin-right: 24px;
}

.mb-4 {
  margin-bottom: 4px;
}

.mb-8 {
  margin-bottom: 8px;
}

.mb-16 {
  margin-bottom: 16px;
}

.mb-24 {
  margin-bottom: 24px;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

// Padding
.p-24 {
  padding: 24px;
}

.px-24 {
  padding: 0 24px 0 24px;
}

// Text
.text-sm {
  font-size: 14px;
}

.text-md {
  font-size: 18px;
}

.text-bold {
  font-weight: bold;
}

.text-roboto-condensed {
  font-family: 'Roboto Condensed', sans-serif;
}

.text-secondary {
  color: $ui-secondary;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.scroll-style {
  @supports (scrollbar-width: thin) or (scrollbar-color: auto) {
    scrollbar-color: $ui-neutral $ui-key;
    scrollbar-width: thin;
  }
  @supports not ((scrollbar-width: thin) or (scrollbar-color: auto)) {
    &::-webkit-scrollbar-track {
      border-radius: 6px;
      background-color: $ui-key;
    }
    &::-webkit-scrollbar {
      width: 6px;
      border-radius: 6px;
      background-color: $ui-neutral;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 6px;
      background-color: $ui-neutral;
    }
  }
}

.spacer {
  flex: 1;
}

.flex {
  display: flex;
  align-items: center;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-end {
  justify-content: end;
}

.space-20 {
  flex: 0 0 20px;
}

.hidden {
  display: none !important;
}
