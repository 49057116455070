.main {
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 0;
  min-width: 1024px;
  margin: 40px auto;
  width: 100%;
  max-width: 1200px;

  &-title-text {
    color: $ui-main;
  }

  &--header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 56px;
  }

  .version-showing {
    color: var(--ui-web-background);
    text-align: center;
    font-size: 10px;
    margin-top: 30px;
    margin-bottom: -30px;
  }
}

.invisible {
  opacity: 0;
}
