.dropdown {
  position: relative;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  cursor: pointer;

  height: 46px;
  border-radius: 31px;
  border: 1px solid $ui-border;
  background-color: $ui-solid;

  padding-left: 24px;
  padding-right: 24px;
  color: $ui-main-selected;

  .visible {
    display: block;
  }

  &-content {
    display: none;
    position: absolute;
    box-sizing: border-box;

    top: 100%;
    left: 28px;
    width: calc(100% - 28px * 2);
    margin-top: 1px;
    padding: 10px 10px 24px 20px;

    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    border: 1px solid $ui-border;
    border-top: none;
    background-color: $ui-solid;
    z-index: 8;
    overflow: hidden;

    .scroll-container {
      display: flex;
      flex-direction: column;

      overflow-y: auto;
      overflow-x: hidden;
      padding-right: 10px;
    }
  }

  &-item {
    display: flex;
    align-items: center;

    padding: 10px;
    border-radius: 4px;

    &:hover {
      background-color: rgba(0, 0, 0, 0.1);
    }
  }

  &-icon {
    height: 24px;
    width: 24px;
    margin-right: 10px;
  }

  &-rightComponent {
    display: flex;
    align-items: center;
    margin-left: auto;
  }

  &-label {
    line-height: 12px;
    font-style: 11px;
    padding: 0px 8px;
    position: absolute;
    top: -6px;
    left: 40px;
    background: $ui-solid;
    border-radius: 10px;
    padding-top: 2px;
    color: $ui-secondary;
  }
}
