.tabs-view-container {
  height: 100%;

  .tabs-navigation {
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;

    align-items: center;
    display: flex;
    justify-content: flex-start;

    .tab {
      padding: 8px 16px;
      cursor: pointer;
      color: $ui-secondary;
    }

    .active {
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      cursor: default;
      color: $ui-main;
      background-color: $ui-web-background;
    }
  }

  .tabs-content-left-radius {
    border-top-left-radius: 8px;
  }

  .tabs-content {
    height: 100%;
    box-sizing: border-box;
    padding: 32px;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
    background-color: $ui-web-background;
  }
}