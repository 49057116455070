.upload-dragndrop,
.upload-uploading,
.upload-success,
.upload-error {
  display: none;
}

.upload {
  background-color: $ui-el-background;
  border: 2px dashed $ui-border;
  border-radius: 15px;
  cursor: pointer;
  overflow: hidden;

  display: flex;

  .upload-input {
    flex: 1;
    cursor: pointer;

    display: flex;

    .upload-file {
      display: none;
    }

    label {
      flex: 1;
      cursor: pointer;
      padding: 24px 62px;

      display: flex;
      flex-direction: column;
      align-items: center;

      img {
        margin-bottom: 24px;
      }

      .upload-info {
        display: flex;
        align-items: center;

        .upload-info-text {
          margin-right: 8px;
          color: $ui-secondary;
          font-size: 24px;
        }
      }
    }
  }

  .upload-dragndrop {
    display: inline;
  }

  .upload.is-dragover {
    background-color: $ui-secondary;
  }
}