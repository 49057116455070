.content {
  background-color: $ui-el-background;
  border-radius: 24px;
  width: 100%;
  min-height: calc(100vh - 250px);
  display: flex;
  flex-direction: row;
  box-shadow: $main-window-shadow;

  &--sidebar {
    max-width: 320px;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
  }

  &--main {
    display: flex;
    flex-direction: column;
    background-color: $ui-main-background;
    width: 100%;
    min-height: calc(100vh - 250px);
    box-sizing: border-box;
    padding: 56px 40px;
    border-radius: 24px;
    box-shadow: $main-window-shadow;
    min-height: 0;
  }
}
