.table {
  color: $ui-main;
  height: 100%;
  box-sizing: border-box;

  .table-layout {
    display: grid;
    min-width: 100%;
    grid-template-columns: 1.5fr 2.5fr 3fr 3fr 105px 100px;
    padding: 0 8px;
    border-radius: 4px;
    height: 32px;
  }

  .table-row {
    padding: 8px 0;

    .table-column {
      display: flex;
      flex-direction: row;
      align-items: center;

      .coin {
        margin-left: 5px;
        margin-right: 5px;
        height: 14px;
        width: 14px;
      }

      &:first-child {
        padding-left: 15px;
      }

      &:last-child {
        padding-right: 15px;
      }
    }

    .bold {
      font-weight: 700;
    }

    &:hover {
      background-color: $ui-web-background;
    }

    .icons {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;

      a {
        margin-left: 10px;
      }

      svg {
        cursor: pointer;
        margin-left: 10px;

        &:last-child {
          margin-left: auto;
        }
      }
    }
  }

  .table-header {
    font-weight: 700;
    padding-left: 32px;
    padding-right: calc(32px + 15px + 4px); // default + additional + scroll width
    box-sizing: border-box;
    color: $ui-border;

    .selected {
      color: $ui-main;
    }

    .selectable {
      cursor: pointer;
    }

    div:first-child {
      padding-left: 15px;
    }

    svg {
      margin-left: 5px;
      margin-top: 2px;
    }

    .flip {
      svg {
        transform: rotate(180deg);
      }
    }
  }

  .table-container {
    padding: 32px;
    box-sizing: border-box;

    &.scroll-container {
      overflow-y: scroll;
      overflow-x: hidden;
      padding-right: 15px;
    }
  }

  .table-user {
    display: grid;
    grid-template-columns: 1fr 150px 1fr 110px 60px 1fr;
    padding: 0 8px;
    height: 32px;

    div {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    .badge-container {
      padding-top: 17px;
    }

    .user {
      padding-right: 15px;
      font-weight: 700;

      img {
        margin-right: 10px;
        border-radius: 50%;
        height: 21px;
        width: 21px;
      }
    }

    .separator {
      margin-top: 15px;
      margin-right: 15px;
      height: 0;
      border-bottom: 1px dashed $ui-border;
      transform: matrix(-1, 0, 0, 1, 0, 0);
    }

    .approve {
      height: 17px;
      font-size: 11px;
      line-height: 12px;
      background-color: $ui-web-background;
      color: $brand-primary;
      border: 1px solid $brand-primary;
      box-sizing: border-box;
      border-radius: 4px;
      margin-left: auto;
      cursor: pointer;
    }
  }

  .badge {
    margin-left: auto;
  }

  .center {
    text-align: center;
  }
}
