@import '../../../../../constants/colors.scss';

.payment-panel {
  padding: 24px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .description {
    min-height: 90px;
  }
  .button-container {
    margin-top: 30px;
    width: 100%;
  }

  .available-credits {
    color: $ui-main;
    background: $ui-main-background;
    text-align: center;
    border-radius: 16px;
    padding: 10px 0px;
    margin-top: 15px;
    width: 100%;
    border: 1px solid var(--ui-inactive);
  }
  .horizontal-spacer {
    height: 1px;
    width: calc(100% - 24px);
    background: $ui-neutral;
    margin: 24px 0;
    align-self: center;
  }

  .token-price {
    display: flex;
    justify-content: space-between;
    width: 100%;
    h5 {
      display: flex;
      flex-direction: column;
      height: 40px;
      .discountInfo {
        display: flex;
        margin-top: 5px;
        .discounted-info-icon {
          width: 16px;
          height: 16px;
          filter: var(--filter-sidebarmenuitem-img);
        }
      }
    }

    .ticker {
      display: flex;
      gap: 4px;
      img {
        width: 100%;
        height: 18px;
      }
    }
  }

  .button {
    align-self: center;
    margin-top: auto;
    width: 100%;
    font-size: 14px;
    .button-icon {
      width: 20px;
      height: 20px;
    }
  }

  .ps {
    text-align: center;
    font-size: 10px;
    width: 100%;
    margin-top: 15px;
  }
}
