.metric-indicator .metric-indicator-item {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: stretch;
  align-content: center;
  position: relative;
  flex-grow: 1;
  width: 0%;
  padding-right:15px;
  height: 60px;
  cursor: default;

  &:before {
    content:"";
    height: 48px;
    position: absolute;
    left: -15px;
    top:7.5px;
    border-left: 1px solid $ui-key;
    padding-left: 15px;
  }

  span {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 15px;
    color: $ui-secondary;
    margin-bottom: 9px;
    padding-right: 15px;
  }

  strong {
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 19px;
    line-height: 21px;
    letter-spacing: -0.01em;
    color: $ui-secondary;
  }

  &:hover {
    strong {
      color:$brand-primary;
    }
  }

  &:first-child {
    &:before {
      border-left:none;
    }
  }
}
