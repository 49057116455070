.pool-name + .campaign-message {
  padding-top: 0;
}
.campaign-message {
  text-align: center;
  margin-top: -20px;
  position: relative;
  padding-top: 20px;
  z-index: 2;
  img.edit-icon {
    cursor: pointer;
    height: 20px;
    width: 20px;
    filter: var(--filter-sidebarmenuitem-img);
    vertical-align: middle;
    margin-left: 4px;
  }
  .campaign-message-preview {
    min-height: 16px;
    font-size: 14px;
    margin-bottom: 30px;
  }
  .campaign-message-area {
    padding-top: 15px;
    padding-bottom: 15px;
    + .pool-form-error {
      margin-top: 0px;
      margin-bottom: 20px;
    }
  }
  .campaign-message-buttons {
    display: flex;
    justify-content: space-between;
  }
  .textField.textArea.disabled {
    textarea {
      border-width: 13px !important;
      cursor: default;
      background-color: $ui-inactive !important;
      border-color: $ui-inactive !important;
      color: var(--ui-secondary) !important;
    }
    background-color: $ui-inactive;
  }
}
