@mixin placeholder {
  ::-webkit-input-placeholder {
    @content;
  }
  :-moz-placeholder {
    @content;
  }
  ::-moz-placeholder {
    @content;
  }
  :-ms-input-placeholder {
    @content;
  }
}

.theme-selector-container {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;
  .image-container {
    position: relative;
    cursor: pointer;
    .theme-img {
      width: 100px;
      height: 100px;
      border: $ui-main-background 6px solid;
      box-shadow: $main-window-shadow;
      border-radius: 50%;
    }
    .check {
      position: absolute;
      width: 40px;
      height: 40px;
      left: 0;
      bottom: 80px;
    }
  }
}
