.inputPhoto {
  height: 80%;
  width: 100%;
  background: $ui-el-background;
  max-height: 399px;
  border: 3px dotted $ui-border;
  border-radius: 15px;
  position: relative;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  .label-container {
    cursor: pointer;
    .void-div {
      width: 100%;
      min-height: 262px;
    }
    img {
      max-width: 500px;
      max-height: 262px;
    }
    .label-text {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .circle {
        align-self: center;
        background: $ui-inactive;
        width: 102px;
        height: 102px;
        border-radius: 50%;
      }
      h6 {
        margin: 10px 0;
      }
    }
  }
  input {
    display: none;
  }
}
