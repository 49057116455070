@import "./MetricsTableItem";

.metrics-table {


  &-header {
    padding: 15px 32px;
    padding-right:42px;
    box-sizing:border-box;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    width:100%;
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    line-height: 15px;
    color: $ui-border;
    margin-top: 30px;
    span {
      width:20%;
      &.wide {
        width: 40%;
      }
      &.text-right {
        text-align: right;
      }
    }
  }
  &-body {
    padding: 15px 15px;
    box-sizing:border-box;
    border-radius: 24px;
    background: $ui-el-background;
  }
}
