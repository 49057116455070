.separator {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .left-component {
    margin-right: 24px;
  }
  .right-component {
    margin-left: 24px;
  }

  .filled {
    flex-grow: 1;
    border-top: 1px dashed $ui-border;
  }
}
