.modal {
  background: $ui-main-background;
  box-shadow: $main-window-shadow;
  border-radius: 16px;
  padding: 40px;
  width: 800px;
  max-height: 600px;

  position: fixed;
  z-index: 10;
  left: calc(50vw - (800px / 2));
  top: calc(50vh - (600px / 2));

  .modal-header {
    font-weight: bold;
    font-size: 19px;
    margin-bottom: 32px;

    display: flex;

    .modal-close {
      filter: var(--filter-sidebarmenuitem-img);
      cursor: pointer;
    }
  }

  .modal-form {
    background: $ui-el-background;
    border-radius: 16px;
    padding: 16px 16px 48px;
    margin: 32px 0;
    max-height: 350px;
    overflow-y: auto;

    .modal-form-header {
      font-weight: bold;
      font-size: 14px;
      color: $ui-neutral;
      margin-bottom: 11px;
    }

    .modal-form-duration {
      margin-bottom: 24px;

      .textField {
        margin-right: 10px;
        flex: 1;
      }

      .dropdown {
        width: 200px;
      }
    }

    .pool-form-locking-container {
      margin-bottom: 24px;

      .pool-form-locking {
        width: 100%;
      }
    }
  }

  .modal-buttons {
    display: flex;
    justify-content: center;
  }
}
