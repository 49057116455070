.dark_red {
  /* colors */
  --brand-primary: #5466fd;
  --brand-hover: #4256FF;
  --brand-secondary: #7A87F6;
  --brand-thirtiary: #373D74;
  --brand-element: #3956BE;
  --brand-background: #32376C;

  --ui-main: #FBFBFB;
  --ui-secondary: #A89F9F;
  --ui-border: #9D9090;
  --ui-el-background: #594F4F;
  --ui-inactive: #675B5B;
  --ui-main-background: #4C4343;
  --ui-web-background: #3C3434;
  --ui-seperator: #8A7B7B;
  --ui-neutral: #8A7B7B;
  --ui-contrast: #706666;
  --ui-key: #8A7B7B;
  --ui-main-selected: #594F4F;
  --ui-element-secondary: #6C6060;
  --ui-outline: #736868;
  --ui-solid: #FFFFFF;
  --ui-hoover: rgba(168, 156, 156, 0,15);
  --ui-paragraph: #000000;

  --attention-alarm: #FF3877;
  --attention-alarm-hover: #FA5A8D;
  --attention-alarm-border: #E584A3;
  --attention-alarm-background: #684053;
  --attention-alarm-inactive: #91435C;
  --attention-warning: #FAD30B;
  --attention-warning-background: #FDF8DC;
  --attention-neutral: #AF5570;
  --attention-positive: #95F972;

  --dark-tile-background: #fbfbfb;
  --blue-tile-background: #6587ff;
  --white-tile-background: #404068;

  --text-dark-background-color: #ffffff;
  --tab-menu-color: #9fa0a8;
  --tab-menu-active-color: #ffffff;

  /* effects */
  --top-bar-shadow: 0px 8px 22px rgba(28, 21, 21, 0.4);
  --main-window-shadow: 0px 8px 24px rgba(41, 34, 34, 0.8);
  --selected-window-shadow: 0px 18px 41px rgba(40, 33, 33, 0.79);

  --popover-background: rgba(76, 67, 67, 1);
  --popover-shadow: 0px 4px 21px rgba(40, 33, 33, 0.81);

  --popover-dark-background: rgba(76, 67, 67, 1);
  --popover-dark-shadow: 0px 7px 24px rgba(13, 18, 28, 0.34);

  --input-field-background: rgba(76, 67, 67, 1);
  --input-field-background-bright: rgba(76, 67, 67, 1);

  --dark-tile-shadow: 0px 7px 19px rgba(21, 17, 17, 0.52);
  --blue-tile-shadow: 0px 7px 19px rgba(22, 41, 199, 0.52);
  --white-tile-shadow: 0px 8px 24px rgba(45, 42, 42, 0.8);
  --menu-button-shadow: 0px 0px 8px rgba(26, 50, 250, 0.16), inset 0px 4px 4px rgba(2, 20, 171, 0.01);

  --dark-tile-hover-shadow: 0px 7px 22px rgba(21, 17, 17, 0.62);
  --blue-tile-hover-shadow: 0px 7px 22px rgba(28, 44, 194, 0.62);
  --white-tile-hover-shadow: 0px 8px 24px rgba(45, 42, 42, 0.8);

  --blue-shadow: 0px 7px 28px rgba(31, 51, 216, 0.57);

  --filter-sidebarmenuitem-img: brightness(0) invert(1);
  --filter-sidebarmenuitem-span: brightness(0) invert(1);
  --filter-sidebarmenuitem-img-invert: brightness(0);
}