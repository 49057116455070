$top-bar-shadow: var(--top-bar-shadow);
$main-window-shadow: var(--main-window-shadow);
$selected-window-shadow: var(--selected-window-shadow);

$popover-background: var(--popover-background);
$popover-shadow: var(--popover-shadow);

$popover-dark-background: var(--popover-dark-background);
$popover-dark-shadow: var(--popover-dark-shadow);

$input-field-background: var(--input-field-background);
$input-field-background-bright: var(--input-field-background-bright);

$dark-tile-shadow: var(--dark-tile-shadow);
$blue-tile-shadow: var(--blue-tile-shadow);
$white-tile-shadow: var(--white-tile-shadow);
$menu-button-shadow: var(--menu-button-shadow);

$dark-tile-hover-shadow: var(--dark-tile-hover-shadow);
$blue-tile-hover-shadow: var(--blue-tile-hover-shadow);
$white-tile-hover-shadow: var(--white-tile-hover-shadow);

$blue-shadow: var(--blue-shadow);

$filter-sidebarmenuitem-img: var(--filter-sidebarmenuitem-img);
$filter-sidebarmenuitem-img-invert: var(--filter-sidebarmenuitem-img-invert);
$filter-sidebarmenuitem-span: var(--filter-sidebarmenuitem-span);