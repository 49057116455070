.transactions-buttons {
  display: flex;
  flex-direction: row-reverse;

  button {
    margin-left: 15px;
  }
}

.transaction-title {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;

  .transaction-title-back {
    display: flex;
    align-items: center;

    img {
      cursor: pointer;
      margin-right: 10px;
      height: 32px;
      width: 32px;
      filter: $filter-sidebarmenuitem-img;
    }
  }
}

.transactions-row-content {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
}

.transaction-section-header {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;

  .transaction-section-info {
    height: 20px;
    width: 20px;
  }

  h5 {
    margin: 0;
    color: $ui-secondary;
  }
}

.transaction-section {
  margin-top: 25px;

  .separator {
    margin: 12px 0;
  }
}

.wallet-copy {
  display: flex;
  align-items: center;
  cursor: pointer;

  img {
    margin-left: 10px;
  }
}

.transaction-button-container {
  display: flex;
  margin-top: auto;
  align-self: flex-end;

  button {
    margin-left: 40px;

    &:first-child {
      margin-left: 0;
    }
  }
}

.transaction-address-container {
  background: $ui-el-background;
  margin-top: 24px;
  padding: 32px;
}

.send-dropdowns {
  display: flex;
  justify-content: space-between;
  margin-top: 16px;

  .dropdown:first-child {
    width: 40%;
  }

  .dropdown:last-child {
    width: 55%;
  }
}

.padded-container {
  padding: 32px;
}
