$brand-primary: var(--brand-primary);
$brand-hover: var(--brand-hover);
$brand-secondary: var(--brand-secondary);
$brand-thirtiary: var(--brand-thirtiary);
$brand-element: var(--brand-element);
$brand-background: var(--brand-background);

$ui-main: var(--ui-main);
$ui-secondary: var(--ui-secondary);
$ui-border: var(--ui-border);
$ui-el-background: var(--ui-el-background);
$ui-inactive: var(--ui-inactive);
$ui-main-background: var(--ui-main-background);
$ui-web-background: var(--ui-web-background);
$ui-seperator: var(--ui-seperator);
$ui-neutral: var(--ui-neutral);
$ui-contrast: var(--ui-contrast);
$ui-key: var(--ui-key);
$ui-main-selected: var(--ui-main-selected);
$ui-element-secondary: var(--ui-element-secondary);
$ui-outline: var(--ui-outline);
$ui-solid: var(--ui-solid);
$ui-hoover: var(--ui-hoover);
$ui-paragraph: var(--ui-paragraph);

$attention-alarm: var(--attention-alarm);
$attention-alarm-hover: var(--attention-alarm);
$attention-alarm-border: var(--attention-alarm-border);
$attention-alarm-background: var(--attention-alarm-background);
$attention-alarm-inactive: var(--attention-alarm-background);
$attention-warning: var(--attention-warning);
$attention-warning-background: var(--attention-warning-background);
$attention-neutral: var(--attention-neutral);
$attention-positive: var(--attention-positive);

$dark-tile-background: var(--dark-tile-background);
$blue-tile-background: var(--blue-tile-background);
$white-tile-backgroundu: var(--white-tile-background);

$text-dark-background-color: var(--text-dark-background-color);
$tab-menu-color: var(--tab-menu-color);
$tab-menu-active-color: var(--tab-menu-active-color);
