@keyframes spinAnimation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.button {
  background: none;
  border: none;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  outline: none;
  cursor: pointer;
  border-radius: 33px;
  transition: background-color 0.17s ease-in;

  div {
    display: flex;
    align-items: center;

    &.left .button-icon {
      margin-right: 5px;
    }

    &.right {
      flex-direction: row-reverse;
      .button-icon {
        margin-left: 5px;
      }
    }
  }

  .button-icon {
    display: inline-block;
    width: 24px;
    height: 24px;
    align-items: center;
    mask-repeat: no-repeat;
    mask-size: 100%;
  }

  &--primary {
    background: $brand-primary;
    border: 1px solid $brand-primary;
    color: $ui-solid;

    &:hover {
      border-color: $brand-hover;
      background: $brand-hover;
    }

    &.button--shadow {
      box-shadow: $blue-tile-shadow;
      &[disabled] {
        box-shadow: none;
      }
    }

    .button-icon {
      background: $ui-solid;
    }
  }

  &--primary-empty {
    background: $ui-main-background;
    border: 1px solid $brand-primary;
    color: $brand-primary;

    &:hover {
      background: $ui-web-background;
    }

    &.button--shadow {
      box-shadow: $blue-tile-shadow;
      &[disabled] {
        box-shadow: none;
      }
    }

    .button-icon {
      background: $brand-primary;
    }
  }

  &--secondary {
    background: $ui-main-background;
    border: 1px solid $ui-border;
    color: $ui-secondary;

    &:hover {
      border-color: $ui-secondary;
      background: $ui-main-background;
    }

    &.button--shadow {
      box-shadow: $white-tile-shadow;
      &[disabled] {
        box-shadow: none;
      }
    }

    .button-icon {
      background: $ui-secondary;
    }
  }

  &--negative {
    background: $ui-main-background;
    border: 1px solid $attention-alarm;
    color: $attention-alarm;

    &:hover {
      background: $ui-web-background;
    }

    &.button--shadow {
      box-shadow: $blue-tile-shadow;
      &[disabled] {
        box-shadow: none;
      }
    }

    .button-icon {
      background: $attention-alarm;
    }
  }

  &--tertiary {
    background: none;
    border: none;
    color: $ui-main;

    &:hover {
      color: $ui-paragraph;

      .button-icon {
        background: $ui-paragraph;
      }
    }

    .button-icon {
      background: $ui-main;
    }
  }

  &--process {
    background: $attention-alarm-background;
    border: 1px solid $attention-alarm-background;
    color: $attention-alarm;

    &:hover {
      border-color: $attention-alarm-background;
      background: $attention-alarm-background;
    }

    .button-icon {
      background: $attention-alarm;
    }
  }

  &--spin {
    .button-icon {
      animation: spinAnimation 2s infinite linear;
      display: inline-block;
    }
  }

  &--small {
    padding: 15px;
    font-size: 13px;
    line-height: 15px;
    min-width: 50px;
    height: 26px;

    .button-icon {
      transform: scale(0.8);
    }
  }

  &--medium {
    padding: 13px 24px;
    min-width: 140px;
    font-size: 16px;
    line-height: 18px;
    height: 44px;

    .button-icon {
      margin-left: 5px;
      margin-right: 5px;
    }

    .right .button-icon {
      margin-left: 5px;
    }

    .left .button-icon {
      margin-right: 5px;
    }
  }

  &--auto {
    padding: 11px;
    font-size: 16px;
    line-height: 18px;
    height: 44px;
  }

  &--large {
    padding: 20px;
    min-width: 220px;
    font-size: 16px;
    line-height: 18px;
    height: 64px;

    .right .button-icon {
      margin-left: 10px;
    }

    .left .button-icon {
      margin-right: 10px;
    }
  }

  &[disabled] {
    cursor: not-allowed;
    background: $ui-inactive;
    border-color: $ui-inactive;
    color: $ui-border;

    .button-icon {
      background: $ui-border;
    }
  }
}
