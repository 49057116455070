.menu-button {
  display: flex;
  justify-content: center;
  align-items: center;

  height: 40px;
  width: 40px;
  border-radius: 25px;
  box-shadow: $menu-button-shadow;

  &-icon {
    height: 24px;
    width: 24px;
  }
}