@import './ListItemUser/index.scss';

.list {
  padding: 16px 32px;
  border-radius: 16px;

  > * {
    padding-top: 8px;
    padding-bottom: 8px;
  }

  &:first-child {
    margin-top: 16px;
  }

  &:last-child {
    margin-bottom: 16px;
  }

  &--background {
    background-color: $ui-el-background;
  }

  &-title--wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  &-title {
    font-size: 16px;
    color: $ui-secondary;
    margin-bottom: 16px;
  }

  &-spacer {
    height: 16px;
  }
}
