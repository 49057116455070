.stepper-container,
.stepper {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 10px;
  margin-top: 2px;
}

.step-container {
  height: 10px;
  width: 10px;
  margin-right: 12px;

  .title {
    color: $ui-secondary;
    margin-right: 12px;
  }

  .step {
    height: 10px;
    width: 10px;

    background: $brand-primary;
    opacity: 0.2;
    border-radius: 5px;

    transition: width 0.3s, opacity 0.3s;

    &.active {
      opacity: 1;
      width: 34px;
    }
  }
}
