@mixin placeholder {
  ::-webkit-input-placeholder {
    @content;
  }
  :-moz-placeholder {
    @content;
  }
  ::-moz-placeholder {
    @content;
  }
  :-ms-input-placeholder {
    @content;
  }
}

.color-selector-container {
  height: 300px;
  margin-bottom: 50px;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;
  .image-container {
    position: relative;
    cursor: pointer;
    width: 14.28%;
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    .color-circle {
      width: 2.5rem;
      height: 2.5rem;
      border: $ui-main-background 6px solid;
      box-shadow: 0px 8px 24px rgba(226, 227, 241, 0.8);
      border-radius: 50%;

      &.active {
        border: $brand-primary 6px solid;
      }
    }
    .check {
      position: absolute;
      width: 40px;
      height: 40px;
      left: 8px;
      bottom: 1.25rem;
    }
  }
}
