.metrics-table .metrics-table-item {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  width:100%;
  font-family: Roboto;
  font-style: normal;
  font-size: 14px;
  line-height: 16px;
  color: $ui-main;
  padding:15px;
  box-sizing:border-box;
  border-radius: 16px;
  padding-right:25px;
  img.actionCaret {
    position: absolute;
    margin-left: 10px;
    margin-top: 3px;
  }
  &:hover {
    background-color: $ui-hoover;
  }
  > span {
    width:20%;
    &.wide {
      width: 40%;
    }
    &.text-right {
      text-align: right;
    }
  }
}
