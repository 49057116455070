.data-table-cards {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  .fund-card {
    margin-left: 20px;

    &:first-child {
      margin-left: 0;
    }
  }
}

.data-table-title {
  display: flex;
  justify-content: space-between;

  .input-container {
    display: flex;
    align-items: center;

    .info-icon {
      height: 20px;
      width: 20px;
      margin-left: 10px;
      filter:$filter-sidebarmenuitem-img
    }
  }
}

.data-table-data {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.data-table-label {
  color: $ui-secondary;
  padding-left: 25px;
  display: flex;
  margin-bottom: 5px;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 15px;
}

.data-table-dropdown-container {
  .dropdown {
    min-width: 250px;
  }
}

.data-table-protocol-choose-container {
  margin-top: 30px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  width: 100%;
}

.data-table-subtitle {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;

  h4 {
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    color: $ui-secondary;
  }

  .input-container {
    display: flex;
    align-items: center;

    .info-icon {
      height: 20px;
      width: 20px;
      margin-left: 10px;
    }
  }
}

